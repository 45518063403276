import React from "react";
import "../styles/Footer.scss";
import Logo from "../assets/logo.svg";
import { Jump } from "react-jump";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__image">
            <img src={Logo} alt="logo" className="footer__logo" />
          </div>
          <div className="footer__pages">
            <ul className="footer__list">
              <li className="footer__item"><Jump target={".intro"}>Acerca de</Jump></li>
              <li className="footer__item "><Jump target={".services"}>Servicios</Jump></li>
              <li className="footer__item "><Jump target={".about"}>¿Quiénes somos?</Jump></li>
              <li className="footer__item"><Jump target={".features"}>Objetivos </Jump></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
