import React, { useState } from "react";
import swal from "sweetalert";
import "../styles/Home.scss";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";
import Menu from "../components/Menu.jsx";
import Footer from "../components/Footer.jsx";
import HeroImage from "../assets/img-principal.png";
import CircleOne from "../assets/circle_1.png";
import CircleTwo from "../assets/circle_2.png";
import CircleThree from "../assets/circle_3.png";
import CircleFour from "../assets/circle_4.png";
import CircleFive from "../assets/circle_5.png";
import CircleSix from "../assets/circle_6.png";
import CircleSeven from "../assets/circle_7.png";
import CircleEight from "../assets/circle_8.png";
import IntroImage from "../assets/img-intro.png";
import ServiceImage from "../assets/img-service.png";
import MoreIcon from "../assets/more-icon.svg";
import MinusIcon from "../assets/minus-icon.svg";
import AboutImage from "../assets/about-img.png";
import ContactImage from "../assets/contact-img.png";

export default function Home() {
  const [services, setServices] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [develop, setDevelop] = useState(false);
  const [client, setClient] = useState(false);

  const sendEmail = e => {
    e.preventDefault();

    const params = {
      nombre: e.target[0].value,
      correo: e.target[1].value,
      mensaje: e.target[2].value
    };
    fetch("https://humantechpro.com/api/sendEmail.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(response => {
        if (response.result) {
          swal({
            icon: "success",
            title: "Success!",
            text: "The Request has been sent successfuly!"
          }).then(() => {
            document.getElementById("name").focus();
          });
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("telephone").value = "";
        } else {
          swal({
            icon: "error",
            title: "Error!",
            text: "Something was happend!"
          });
        }
      });
  };

  return (
    <>
      <Menu />
      <section className="hero">
        <div className="hero__container">
          <Flip top cascade>
            <h1 className="hero__text hero__text--one">Cally</h1>
            <h1 className="hero__text hero__text--two">Tek </h1>
            {/* <h1 className="hero__text hero__text--three">pro</h1> */}
          </Flip>

          <p className="hero__slogan">Born to make the best work </p>

          {/* <button className="hero__button">
            ver más
            <img src={ArrowDown} alt="arrow" className="hero__arrow" />
          </button> */}
        </div>
        <Zoom right cascade>
          <div className="hero__box">
            <img
              src={CircleOne}
              alt="Circle"
              className="hero__image hero__image--circleOne"
            />
            <img src={HeroImage} alt="Hero" className="hero__image" />
            <img
              src={CircleTwo}
              alt="Circle"
              className="hero__image hero__image--circleTwo"
            />
          </div>
        </Zoom>
      </section>
      <section className="intro">
        <Fade bottom cascade>
          <div className="intro__container">
            <img
              src={CircleThree}
              alt="circle"
              className="intro__image intro__image--circleOne"
            />
            <img src={IntroImage} alt="Intro" className="intro__image " />
            <img
              src={CircleFour}
              alt="circle"
              className="intro__image--circleTwo"
            />
          </div>
        </Fade>
        <Slide right cascade>
          <div className="intro__text">
            <h2 className="intro__subtitle">Introducción</h2>
            <p className="intro__paragraph">
              Somos una empresa con experiencia en los diferentes modelos de
              negocio existentes en el ámbito tecnológico, financiero,
              administrativo y creativo; nuestra empresa soluciona las
              necesidades actuales que surgen con la nueva era digital
              posicionándonos como una empresa de confianza incursionando en el
              sector salud y belleza, así como en el turismo y hotelería.
            </p>
          </div>
        </Slide>
      </section>
      <section className="text">
        <div className="text__container">
          <h2 className="text__paragraph">
            Trabajos basados en
            <span className="text__paragraph--color">
              conocimiento, creatividad y tecnología
            </span>
          </h2>
        </div>
      </section>
      <section className="services">
        <div className="services__section">
          <Slide right>
            <div className="services__text">
              <h2 className="services__title">nuestros servicios</h2>
            </div>
          </Slide>

          <div className="services__photo">
            <img src={ServiceImage} alt="Service" className="services__image" />
          </div>
        </div>
        <div className="services__container">
          <article className="services__cards">
            <span className="services__box">
              <h4 className="services__subtitle">servicios financieros</h4>
              {!services && (
                <button
                  onClick={e => setServices(!services)}
                  className="services__button"
                >
                  <img
                    src={MoreIcon}
                    alt="More Icon"
                    className="services__button services__button--icon"
                  />
                </button>
              )}
            </span>
            {services && (
              <span className="services__description">
                <p className="services__paragraph">
                  Movilizamos los recursos de las personas movilizando y
                  canalizando los excedentes de dinero, hacia la adquisición de
                  productos o servicios que generan valores adicionales.
                </p>
                <span className="services__close">
                  <button
                    onClick={e => setServices(!services)}
                    className="services__button"
                  >
                    <img
                      src={MinusIcon}
                      alt="Minus Icon"
                      className="services__button services__button--minus"
                    />
                  </button>
                </span>
              </span>
            )}
          </article>
          <article className="services__cards">
            <span className="services__box">
              <h4 className="services__subtitle">marketing digital</h4>
              {!marketing && (
                <button
                  onClick={e => setMarketing(!marketing)}
                  className="services__button"
                >
                  <img
                    src={MoreIcon}
                    alt="More Icon"
                    className="services__button services__button--icon"
                  />
                </button>
              )}
            </span>
            {marketing && (
              <span className="services__description">
                <p className="services__paragraph">
                  Aplicamos estrategias de comercialización elaboradas en los
                  medios digitales. Utilizamos técnicas de confianza aplicadas
                  en el mundo digital aplicando nuevas herramientas y la
                  posibilidades de mediciones reales de cada una de las
                  estrategias empleadas.
                </p>
                <span className="services__close">
                  <button
                    onClick={e => setMarketing(!marketing)}
                    className="services__button"
                  >
                    <img
                      src={MinusIcon}
                      alt="Minus Icon"
                      className="services__button services__button--minus"
                    />
                  </button>
                </span>
              </span>
            )}
          </article>
          <article className="services__cards">
            <span className="services__box">
              <h4 className="services__subtitle">desarrollo web</h4>
              {!develop && (
                <button
                  onClick={e => setDevelop(!develop)}
                  className="services__button"
                >
                  <img
                    src={MoreIcon}
                    alt="More Icon"
                    className="services__button services__button--icon"
                  />
                </button>
              )}
            </span>
            {develop && (
              <span className="services__description">
                <p className="services__paragraph">
                  Construimos y mantenemos sitios web; trabajamos en segundo
                  plano y que permite que una web tenga una apariencia
                  impecable, un funcionamiento rápido y un buen desempeño para
                  permitir la mejor experiencia de usuario.
                </p>
                <span className="services__close">
                  <button
                    onClick={e => setDevelop(!develop)}
                    className="services__button"
                  >
                    <img
                      src={MinusIcon}
                      alt="Minus Icon"
                      className="services__button services__button--minus"
                    />
                  </button>
                </span>
              </span>
            )}
          </article>
          <article className="services__cards">
            <span className="services__box">
              <h4 className="services__subtitle">servicio al cliente</h4>
              {!client && (
                <button
                  onClick={e => setClient(!client)}
                  className="services__button"
                >
                  <img
                    src={MoreIcon}
                    alt="More Icon"
                    className="services__button services__button--icon"
                  />
                </button>
              )}
            </span>
            {client && (
              <span className="services__description">
                <p className="services__paragraph">
                  Monitoreamos a nuestros clientes antes, durante y después de
                  la compra, logrando cumplir con la satisfacción de un producto
                  o servicio involucrando varias etapas y factores generando la
                  confianza de nuestros clientes.
                </p>
                <span className="services__close">
                  <button
                    onClick={e => setClient(!client)}
                    className="services__button"
                  >
                    <img
                      src={MinusIcon}
                      alt="Minus Icon"
                      className="services__button services__button--minus"
                    />
                  </button>
                </span>
              </span>
            )}
          </article>
        </div>
      </section>
      <section className="about">
        <Fade bottom cascade>
          <div className="about__container">
            <img src={AboutImage} alt="About" className="about__image" />
            <img
              src={CircleSix}
              alt="circle"
              className="about__image about__image--circleTwo"
            />{" "}
            <img
              src={CircleFive}
              alt="circle"
              className="about__image about__image--circleOne"
            />
          </div>
        </Fade>
        <Slide right cascade>
          <div className="about__text">
            <h2 className="about__title">¿Quiénes somos?</h2>
            <h4 className="about__subtitle">
              tecnología avanzada para tus necesidades
            </h4>
            <p className="about__paragraph">
              Somos una compañía con las más alta tecnología compuesta de un
              equipo de expertos abarcando ramas importantes actuales en la
              industria se encuentran en el proceso de crecimiento, optimizamos
              y mejoramos los procesos de producción, organización, despacho,
              ventas y cobranza, capacitación.
            </p>
            <p className="about__paragraph">
              Buscamos profesionales que sean empáticos y piensen en el
              desarrollo constante de productos y procesos.
            </p>
          </div>{" "}
        </Slide>
      </section>
      <section className="features">
        <div className="features__box features__box--objectives">
          <h2 className="features__title">Objetivos</h2>
          <article className="features__text">
            <h4 className="features__subtitle">Objetivos</h4>
            <p className="features__paragraph">
              Favorecemos el desarrollo de tecnologías aplicadas a nivel
              Marketing Digital, Financiero y Empresarial promoviendo
              desarrollar y transferir tecnología optimizando los recursos
              disponibles promoviendo actuaciones conjuntas entre empresa.
            </p>
          </article>
        </div>
        <div className="features__box features__box--experience">
          <h2 className="features__title">Experiencia</h2>
          <article className="features__text">
            <h4 className="features__subtitle">Experiencia </h4>
            <p className="features__paragraph">
              Nuestros métodos han sido aplicados en proyectos en ramas como
              salud y belleza así como en turismo, garantizando que la
              tecnología unida a la experiencia del usuario obtiene unos
              resultados inigualables consiguiendo resultados impecables.
            </p>
          </article>
        </div>
        <div className="features__box features__box--professional">
          <h2 className="features__title">Profesionalismo</h2>
          <article className="features__text">
            <h4 className="features__subtitle">Profesionalismo</h4>
            <p className="features__paragraph">
              Nuestro equipo está formado por expertos, preocupados por tu
              negocio, cubriendo siempre las necesidades de cada uno de nuestros
              proyectos.
            </p>
          </article>
        </div>
      </section>
      <section className="contact">
        <Slide left cascade>
          <div className="contact__form">
            <h4 className="contact__title">
              Nuestro equipo se pondrá en contacto contigo
            </h4>
            <form onSubmit={e => sendEmail(e)}>
              <ul className="contact__list">
                <li className="contact__item">
                  <input
                    type="text"
                    placeholder="Nombre:"
                    id="name"
                    className="contact__input"
                    required
                  />
                </li>
                <li className="contact__item">
                  <input
                    type="text"
                    placeholder="Correo:"
                    id="email"
                    className="contact__input"
                    required
                  />
                </li>
                <li className="contact__item">
                  <input
                    type="text"
                    placeholder="Teléfono:"
                    id="telephone"
                    className="contact__input"
                  />
                </li>
                <li className="contact__item contact__item--button">
                  <button type="submit" className="contact__button">
                    Solicitar Información
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </Slide>
        <Fade bottom cascade>
          <div className="contact__container">
            <img
              src={CircleSeven}
              alt="circle"
              className="contact__image contact__image--circleOne"
            />
            <img src={ContactImage} alt="Contact" className="contact__image" />
            <img
              src={CircleEight}
              alt="circle"
              className="contact__image contact__image--circleTwo"
            />
          </div>
        </Fade>
      </section>
      <Footer />
    </>
  );
}
