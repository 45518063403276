import React, { useState } from "react";
import "../styles/Menu.scss";
import { Jump } from "react-jump";
import Logo from "../assets/logo.svg";
import MenuIcon from "../assets/menu-icon.svg";
import Linkedin from "../assets/linkedin.svg";

export default function Menu() {
  const [mobile, setMobile] = useState(false);
  return (
    <>
      <nav className="menu">
        <div className="menu__container">
          <div className="menu__logo">
            <img src={Logo} alt="Logo" className="menu__logo" />
          </div>
          <div className="menu__box">
            <ul className="menu__list">
              <li className="menu__links menu__links--text">
                <Jump target={".intro"}>Acerca de</Jump>
              </li>
              <li className="menu__links menu__links--text">
                <Jump target={".services"}>Servicios</Jump>
              </li>
              <li className="menu__links menu__links--text">
                <Jump target={".about"}>¿Quiénes somos?</Jump>
              </li>
              <li className="menu__links menu__links--text">
                <Jump target={".features"}>Objetivos </Jump>
              </li>
              <li className="menu__links">
                <button className="menu__links menu__links--button">
                  <Jump target={".contact"}>Contáctanos</Jump>
                </button>
              </li>
              <li className="menu__links">
                <button
                  className="menu__links menu__links--linkedin"
                  onClick={e => {
                    var otherWindow = window.open();
                    otherWindow.opener = null;
                    otherWindow.location =
                      "https://www.linkedin.com/in/humantech-pro/";
                  }}
                >
                  <img src={Linkedin} alt="Linkedin" />
                </button>
              </li>
              <li className="menu__links menu__links--icon">
                <button
                  onClick={e => setMobile(!mobile)}
                  className="menu__button"
                >
                  <img src={MenuIcon} alt="Menu" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {mobile && (
        <nav className="mobile">
          <div className="mobile__container">
            <div className="mobile__box">
              <ul className="mobile__list">
                <li className="mobile__links" onClick={e => setMobile(false)}>
                  <Jump target={".intro"}>Acerca de</Jump>
                </li>
                <li className="mobile__links" onClick={e => setMobile(false)}>
                  <Jump target={".services"}>Servicios</Jump>
                </li>
                <li className="mobile__links" onClick={e => setMobile(false)}>
                  {" "}
                  <Jump target={".about"}>¿Quiénes somos?</Jump>
                </li>
                <li className="mobile__links" onClick={e => setMobile(false)}>
                  {" "}
                  <Jump target={".features"}>Objetivos </Jump>
                </li>
                <li className="mobile__links" onClick={e => setMobile(false)}>
                  <button className="mobile__links mobile__links--button">
                    <Jump target={".contact"}>Contáctanos</Jump>
                  </button>
                </li>
                <li className="mobile__links">
                  <button
                    onClick={() => {
                      var otherWindow = window.open();
                      otherWindow.opener = null;
                      otherWindow.location =
                        "https://www.linkedin.com/in/humantech-pro/";
                    }}
                    className="mobile__links menu__links--linkedin"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
